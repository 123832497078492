import { netpost,netdonwloadexcel } from '../plugins/request'

export default {
  // 获取运单组运单
  getWaybillList(params, loading = true) {
    Object.assign(params, { loading })
    return netpost(`/api/Logistics/GetWaybillList`, params)
  },
  // 获取代理商运单组
  getBatchList(params, loading = true) {
    Object.assign(params, { loading })
    return netpost(`/api/Logistics/GetBatchList`, params)
  },
  // 获取运单客户
  getSupplierByBatch(params, loading = true) {
    Object.assign(params, { loading })
    return netpost(`/api/Logistics/GetSupplierList`, params)
  },
  // 获取运单代理商自代理
  getAgencyListByBatch(params, loading = true) {
    Object.assign(params, { loading })
    return netpost(`/api/Logistics/GetAgencyListByBatch`, params)
  },
  // 数据导出
  DownLoadExciseExport(params, loading = true) {
    Object.assign(params, { loading })
    return netdonwloadexcel(`/api/Logistics/DownLoadExciseExport`, params)
  }
}