<!-- 消費税輸出免税不適用連絡一覧表 -->
<template>
  <div class="logistics-list">
    <div class="list-container">
      <div class="search-container">
        <el-form
          :model="formModel"
          :rules="rules"
          ref="limitForm"
          :inline="true"
        >
          <el-form-item label="运单组" prop="batchKey">
            <el-select
              v-model="formModel.batchKey"
              filterable
              placeholder="请选择"
              clearable
              @change="hangeChangeBatch"
            >
              <el-option
                v-for="item in batchData"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="代理商" prop="agencycode">
            <el-select
              v-model="formModel.agencycode"
              filterable
              placeholder="请选择"
              clearable
              @change="handleChangeAgencyCode"
            >
              <el-option
                v-for="item in agencyChildrenData"
                :key="item.key"
                :label="item.value"
                :value="item.key"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="客户" prop="supperkey">
            <el-select
              v-model="formModel.supperkey"
              filterable
              placeholder="请选择"
              clearable
            >
              <el-option
                v-for="item in supperData"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="輸出免税適用者名" prop="supperName"
            ><el-input
              placeholder="输入輸出免税適用者名"
              v-model="formModel.supperName"
              clearable
            >
            </el-input>
          </el-form-item>
          <el-form-item label="Invoice No" prop="invoiceNo"
            ><el-input
              placeholder="输入Invoice  No"
              v-model="formModel.invoiceNo"
              clearable
            >
            </el-input>
          </el-form-item>
          <el-form-item label="取引日期" prop="reportDate">
            <el-date-picker
              v-model="formModel.reportDate"
              type="date"
              placeholder="请选择取引日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="" prop="isDeliver">
            <el-checkbox v-model="isDeliver">只查询干线发货</el-checkbox>
          </el-form-item>
          <el-form-item>
            <el-button
              @click="handleSearch"
              icon="el-icon-search"
              type="primary"
              >查询</el-button
            >
            <el-button @click="handelExcelPort" type="warning"
              >导出查询数据</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div class="table-container">
        <el-table
          :data="tableData"
          border
          header-cell-class-name="table-header"
        >
          <el-table-column
            type="index"
            :index="calcIndex"
            label="序号"
            width="50"
          >
          </el-table-column>
          <el-table-column prop="waybill_number" label="运单号" width="200">
          </el-table-column>
          <el-table-column label="状态/清关状态" width="200">
            <template slot-scope="scope">
              <span
                >{{ scope.row.waybill_state | handlerstate
                }}{{ scope.row.customs_status }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="logisticsOrders[0].platform_name"
            label="客户"
            width="200"
          >
          </el-table-column>
          <el-table-column label="收货信息">
            <template slot-scope="scope">
              <span>
                {{ scope.row.consignee }}
              </span>
              <span>{{ scope.row.consignee_tel }}</span>
              <span>{{
                scope.row.country +
                scope.row.province +
                scope.row.city +
                scope.row.area +
                scope.row.consignee_address
              }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="page-container">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="pageSizes"
        :page-size="pageSize"
        :layout="pageLayout"
        :total="tableTotal"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { vxRule, rules } from '@/utils/validator'
import { PAGESIZELIST, PAGE_LAYOUT, PAGESIZE } from 'config/index'
import { GetToken } from 'utils/auth'
import Excel from 'api/excel'
import tableIndex from '@/mixins/table-index'
export default {
  components: {},
  name: '',
  mixins: [tableIndex],
  data() {
    let verifySupplier = (rule, value, callback) => {
      if (value) {
        callback()
      } else {
        if (this.formModel.supperkey) {
          if (value == '') {
            callback(new Error('输入輸出免税適用者名'))
          } else {
            callback()
          }
        } else {
          callback()
        }
      }
    }
    return {
      rules: {
        batchKey: vxRule(true, null, 'change', '请选择运单组'),
        invoiceNo: vxRule(true, null, '', '请输入Invoice No'),
        supperName: [
          { validator: verifySupplier, required: false, trigger: 'blur' }
        ],
        reportDate: vxRule(true, null, '', '请选择取引日期')
      },
      currentPage: 1,
      pageSize: PAGESIZE,
      pageLayout: PAGE_LAYOUT,
      pageSizes: PAGESIZELIST,
      tableTotal: 0,
      formModel: {
        batchKey: '', // 运单组
        supperkey: '', // 运单组客户
        supperName: '', // 客户名称
        invoiceNo: '',
        agencycode: '', // 子代理
        reportDate: '' // 申告日期
      },
      batchData: [], // 运单组数据
      supperData: [], // 客户数据
      tableData: [], // 搜索数据
      agencyChildrenData: [], // 自代理
      isDeliver: true, // 只查询干线发货
      agency: this.$route.query.agency
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    this.getBatch()
  },
  filters: {
    handlerstate(code) {
      var _tagname = ''
      if (code != '' && code != null && code != undefined) {
        switch (code) {
          case 200:
            _tagname = '待审核'
            break
          case 250:
            _tagname = '取消订单'
            break
          case 300:
            _tagname = '未处理'
            break
          case 320:
            _tagname = '已审核'
            break
          case 340:
            _tagname = '已取消'
            break
          case 600:
            _tagname = '空箱子'
            break
          case 610:
            _tagname = '已装箱'
            break
          case 800:
            _tagname = '干线发货'
            break
          case 'CANCELORDER':
            _tagname = '取消订单'
            break
          case 'CW':
            _tagname = '待审核'
            break
          case 'EX':
            _tagname = '未处理'
            break
          case 'WAYBILL':
            _tagname = '已审核'
            break
          case 'CANCELWAYBILL':
            _tagname = '已取消'
            break
          case 'NEWBOX':
            _tagname = '空箱子'
            break
          case 'WAYBILLBOX':
            _tagname = '已装箱'
            break
          case 'DELIVER':
            _tagname = '干线发货'
            break
          case 'DELIVERED':
            _tagname = '已完成'
            break
        }
      }
      return _tagname
    }
  },
  destroyed() {},
  methods: {
    getBatch() {
      Excel.getBatchList({
        token: GetToken()
      }).then((res) => {
        if (res.success) {
          this.batchData = res.record
        } else {
          this.$message.error(res.message)
        }
      })
    },
    hangeChangeBatch() {
      if (this.formModel.batchKey) {
        this.getAgencyChildren()
        this.getSupplier()
      }
    },
    handleChangeAgencyCode() {
      this.getSupplier()
    },
    getSupplier() {
      this.formModel.supperkey = ''
      this.supperData = []
      Excel.getSupplierByBatch({
        token: GetToken(),
        agencycode: this.formModel.agencycode,
        keys: this.formModel.batchKey
      }).then((res) => {
        if (res.success) {
          this.supperData = res.record
        } else {
          this.$message.error(res.message)
        }
      })
    },
    getAgencyChildren() {
      this.formModel.agencycode = ''
      this.agencyChildrenData = []
      Excel.getAgencyListByBatch({
        token: GetToken(),
        keys: this.formModel.batchKey
      }).then((res) => {
        if (res.success) {
          this.agencyChildrenData = res.record || []
          this.agencyChildrenData.unshift({
            key: '',
            value: '自身'
          })
          console.log(this.agencyChildrenData, 'this.agencyChildrenData')
        } else {
          this.$message.error(res.message)
        }
      })
    },
    handleSearch() {
      this.$refs.limitForm.validate((valid) => {
        console.log('valid', valid)
        if (valid) {
          Excel.getWaybillList({
            token: GetToken(),
            agencycode: this.formModel.agencycode,
            batch: this.formModel.batchKey,
            supplier: this.formModel.supperkey,
            curr: this.currentPage,
            pagesize: this.pageSize,
            deliver: this.isDeliver ? 1 : 0
          }).then((res) => {
            this.tableData = res.record
            this.tableTotal = +res.message
            if (res.success) {
            } else {
              this.$message.error(res.message)
            }
          })
        }
      })
    },
    handelExcelPort() {
      let _batchName = this.batchData.find((item) => {
        return item.id == this.formModel.batchKey
      }).name
      let _supplierName = ''
      if (this.formModel.supperkey) {
        _supplierName = this.supperData.find((item) => {
          return item.id == this.formModel.supperkey
        }).name
      }
      let _agencyName = ''
      if (this.formModel.agencycode) {
        _agencyName = this.agencyChildrenData.find((item) => {
          return item.key == this.formModel.agencycode
        }).value
      }

      this.$refs.limitForm.validate((valid) => {
        console.log('valid', valid)
        if (valid) {
          Excel.DownLoadExciseExport({
            token: GetToken(),
            agencycode: this.formModel.agencycode,
            batch: this.formModel.batchKey,
            supplier: this.formModel.supperkey,
            suppliername: this.formModel.supperName,
            ino: this.formModel.invoiceNo,
            date: this.formModel.reportDate,
            deliver: this.isDeliver ? 1 : 0
          }).then((data) => {
            let fileName =
              _agencyName +
              _batchName +
              _supplierName +
              this.formModel.invoiceNo +
              this.formModel.reportDate
            if (typeof window.navigator.msSaveBlob !== 'undefined') {
              window.navigator.msSaveBlob(
                new Blob([data], { type: 'application/vnd.ms-excel' }),
                fileName + '.xlsx'
              )
            } else {
              let url = window.URL.createObjectURL(
                new Blob([data], { type: 'application/vnd.ms-excel' })
              )
              let link = document.createElement('a')
              link.style.display = 'none'
              link.href = url
              link.setAttribute('download', fileName + '.xlsx')
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link) //下载完成移除元素
              window.URL.revokeObjectURL(url) //释放掉blob对象
            }
          })
        }
      })
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.currentPage = 1
      this.pageSize = val
      this.tableData = []
      this.handleSearch()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.currentPage = 1
      this.currentPage = val
      this.tableData = []
      this.handleSearch()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.search-container {
  .el-form {
    margin-top: 15px;
  }
}
.attr-item {
  display: flex;
  .attr-info {
    margin-right: 20px;
    a {
      font-size: 13px;
      text-decoration: none;
    }
    .icon-PDF {
      font-size: 13px;
    }
  }
}
</style>
